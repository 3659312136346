.addTitle {
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 10px;
}

.BonDeCommande {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addButtons{
    display: flex;
    align-items: center;
    text-align: center;
}

.addBonDeCommande{
    margin: 0 auto;
    width: 90%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}
/* Lists the portes to be added in collumns */
.portesList, .baiesList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
}

.porte, .baie {
    display: flex;
    flex-direction: column;
    width: 20%;
    min-width: 100px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
}

.fields {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 5px;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px 0px darkgrey;
    text-align: center;
    border-radius: 5px;
}

.porteHeader {
    background-color: lightskyblue;
}

.baieHeader {
    background-color: lightblue;
}

.cardId {
    margin-left: 5px;
}

.blank {
    padding : 18px;
}

.ordersList {
    margin-top: 20px;
    min-width: 50%;
    
    border: 1px solid #ccc;
    border-radius: 5px;
}

.order {
    margin-bottom: 10px;
    padding: 0 10px 0  10px;
}

.orderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.orderId {
    font-weight: bold;
    margin-right: 8px;
}

.orderActions {
    display: flex;
}

.orderItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
}

.orderPortes, .orderBaies {
    display: flex;
    flex-direction: column;
    width: 45%;
}

.orderPorte, .orderBaie {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.orderPorte {
    background-color: lightskyblue;
}

.orderBaie {
    background-color: lightblue;
}

.expandIcon {
    vertical-align: middle;
}