.inventairePortes {
    background-color:lightskyblue ;
}

.inventaireBaies {
    background-color: lightblue;
}

.inventaire {
    padding: 3px;
    border-radius: 5px;
}