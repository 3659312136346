.Header {
  display: flex;
  flex-direction: column;
}

ul, li, a, h2 {
  all: unset;
}

.myHeader {
  display: flex;
  flex-direction: row;
  align-items: left;
  background-color: #76b852;
  color: white;
  font-size: calc(10px + 1vmin);
  padding-right: 10px;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  flex-direction: row;  
  align-items: center;
  padding: 0 30px;
  min-height: 5vh;
  cursor: pointer;
}

li:hover {
  /* white inset box shadow on top */
  box-shadow: inset 0px 2px 0px 0px white;
}

#stock {
  background-color: #5a9b3e;
}