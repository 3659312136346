main {
    display: flex;
    flex-direction: column;
    margin: 1vh;
}

.archiveGroup{
    text-align: center ;
    display: flex;
    align-items: baseline;
    background-color: #90ee90;
}

.stockUtils {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.box {
    height: 20px;
    width: 20px;
    margin-right: 5px;
}

.red {
    background-color: #f75c5c ;
}

.yellow {
    background-color: #ffff66;
    margin-bottom: 15px;
}

.grey {
    background-color: #a8a8a8;
    margin-bottom: 15px;
}

.green {
    background-color: #90ee90;
    margin-bottom: 15px;
}