.Login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #76b852;
  }
  
  .Login form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80vw;
    max-width: 300px;
    padding: 5vw;
    background-color: #ffffff;
    border-radius: 10px;
  }

.menutitle{
    font-size: 12px !important;
}