.StockTab {
    margin: 10px;
}


.DataTableID {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2rem;
    border-radius: 10px;
}

.dateDisplay {
    margin-left: 5px;
    margin-top: 2px;
}

.commentCell {
    display: flex;
    flex-direction: row;
}

.commentCellEmpty {
    display: flex;
    flex-direction: row;
}